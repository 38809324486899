export interface Theme {
  colors: {
    accent: string;
    inactiveAccent: string;
    background: {
      dark: string;
      middle: string;
      light: string;
    };
    text: {
      dark: string;
      middle: string;
      light: string;
      gray: string;
    };
  };
  breakpoints: {
    mobile: {
      up: string;
      down: string;
    };
  };
  spacing: (...nums: number[]) => string;
}

export enum Breakpoints {
  mobile = 960,
  desktop = 1280
}
