import {
  HOME_URL,
  EV_CALCULATOR_URL,
  EV_CHARGER_URL,
  EV_REBATES_URL,
  EV_SOFTWARE_URL,
  BLOG_URL,
  OCPP_URL,
  OPEN_ADR_URL,
} from 'evcharging/app/seo/urls';

interface PageMetadata {
  title: string;
  description: string;
}

interface PagesMetadata {
  [key: string]: PageMetadata;
}

const defaultMetadata = {
  title: `Compare energy rates`,
  description:
    'Stop overpaying! Think ahead and switch your energy supplier today! Experience the most intelligent platform to compare electricity & gas plans. Online, fast & easy.',
};

const metadata: PagesMetadata = {
  [HOME_URL]: defaultMetadata,
  [EV_REBATES_URL]: {
    title: 'Get a incentive for EV charger',
    description:
      'Find a incentive for installing an EVcharger or several on your premises. Save big time in no time with turnkey solutions.',
  },
  [EV_CHARGER_URL]: {
    title: 'Level 2 EV charging solutions',
    description:
      'EV hardware - best level 2 EV charger for your business. Get EV charging station profit through all-in-one solutions.',
  },
  [EV_SOFTWARE_URL]: {
    title: 'Efficient EV charging software',
    description:
      'The right EV charging management software for businesses. Part of a full suite of tailored EV charging solutions.',
  },
  [EV_CALCULATOR_URL]: {
    title: 'EV Charging ROI calculator',
    description:
      'An electric car charging profit calculator to determine how much revenue you can expect from EV charging infrastructure.',
  },
  [BLOG_URL]: {
    title: 'Energy5 blog',
    description:
      'Check the hottest EV charging topics most viewed by our readers. Get updates about technology, insights, expert reviews, and energy-efficient solutions',
  },
  [OCPP_URL]: {
    title: 'EV Charging OCPP',
    description: 'OCPP and its significance to EV Charging.',
  },
  [OPEN_ADR_URL]: {
    title: 'EV Charging OpenADR',
    description:
      'OpenADR allows businesses to reduce electricity use during demand response events automatically',
  },
};

const getMetadata = (pathname: string): PageMetadata => {
  const routes = Object.keys(metadata);
  for (const route of routes) {
    if (new RegExp(pathname).test(route)) {
      return { ...metadata[route] };
    }
  }
  return defaultMetadata;
};

export default getMetadata;
