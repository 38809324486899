import React, { FC } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import getMetadata from "evcharging/app/seo/meta";
import { ENV, HOST_URL } from "evcharging/app";
import useI18n from "evcharging/hooks/useI18n";

export interface MetadataProps {
  title?: string;
  description?: string;
  jsonLd?: string;
  locales?: string[];
}

const Metadata: FC<MetadataProps> = (props) => {
  const { jsonLd, locales = [] } = props;
  const { asPath, defaultLocale, locale } = useRouter();
  const { links } = useI18n();
  const path = asPath.split('?')[0];
  const pageMetadata = getMetadata(path);
  const title = props.title || pageMetadata.title;
  const description = props.description || pageMetadata.description;
  const metaImage = 'https://static.energy5.com/_next/static/media/logo.fa35efed.svg';
  const canonicalUrl = `${HOST_URL}${locale === defaultLocale ? '' : `/${locale}`}${path}`;
  const siteName = 'Energy5';

  return (
    <Head>
      <title>{title}</title>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="description" content={description} />
      <meta property="image" content={metaImage} />
      <meta name="og:type" content="website" />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:image" content={metaImage} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={metaImage} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:url" content={canonicalUrl} />
      <meta itemProp="name" content={title} />
      <meta itemProp="url" content={canonicalUrl} />
      <link rel="canonical" href={canonicalUrl} />

      {locales.map((locale) => (
        <link
          key={locale}
          rel="alternate"
          hrefLang={locale}
          href={
            locale === defaultLocale
              ? `${ENV.HOST_URL}${links[locale] || asPath}`
              : `${ENV.HOST_URL}/${locale}${links[locale] || asPath}`
          }
        />
      ))}
      {links && <link rel="alternate" hrefLang="x-default" href={`${ENV.HOST_URL}${links[defaultLocale] || asPath}`} />}
      {jsonLd && <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: jsonLd }} />}
    </Head>
  );
};

export default Metadata;
