import { Breakpoints, Theme } from "evcharging/app/themes/index";

const lightTheme: Theme = {
  spacing(...nums): string {
    return nums.map((n) => `${n * 8}px`).join(' ');
  },
  colors: {
    accent: "#f9c106",
    inactiveAccent: "rgba(249, 194, 6, 0.2)",
    background: {
      dark: "#373737",
      middle: "#f7f8fa",
      light: "#fff"
    },
    text: {
      dark: "#000",
      middle: "#59606D",
      light: "#fff",
      gray: '#d5dadd',
    }
  },
  breakpoints: {
    mobile: {
      up: `@media (min-width: ${Breakpoints.mobile}px)`,
      down: `@media (max-width: ${Breakpoints.mobile}px)`
    }
  }
};

export default lightTheme;
