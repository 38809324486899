import React from 'react';
import { css, Global, useTheme } from '@emotion/react';
import { Theme } from 'evcharging/app/themes';

const BaselineCss = () => {
  const theme = useTheme() as Theme;
  return (
    <div>
      <Global
        styles={css`
          *,
          *:before,
          *.after {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
          }
          html {
            scroll-behavior: smooth;
          }
          ,
          body {
            font-family: Mulish, sans-serif !important;
            font-size: 1rem;
            color: #000;
            background-color: #fff;
            position: relative;
          }
          .react-multi-carousel-list {
            display: flex;
            align-items: center;
            overflow: hidden;
            position: relative;
          }

          .react-multi-carousel-track {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: row;
            position: relative;
            transform-style: preserve-3d;
            backface-visibility: hidden;
            will-change: transform, transition;
          }

          .react-multiple-carousel__arrow {
            position: absolute;
            outline: 0;
            transition: all 0.5s;
            border-radius: 35px;
            z-index: 1000;
            border: 0;
            background: rgba(0, 0, 0, 0.5);
            min-width: 43px;
            min-height: 43px;
            opacity: 1;
            cursor: pointer;
          }

          .react-multiple-carousel__arrow:hover {
            background: rgba(0, 0, 0, 0.8);
          }

          .react-multiple-carousel__arrow::before {
            font-size: 20px;
            color: #fff;
            display: block;
            text-align: center;
            z-index: 2;
            position: relative;
          }

          .react-multiple-carousel__arrow:disabled {
            cursor: default;
            background: rgba(0, 0, 0, 0.5);
          }

          .react-multiple-carousel__arrow--left {
            left: calc(4% + 1px);
          }

          .react-multiple-carousel__arrow--left::before {
            content: '\\e824';
          }

          .react-multiple-carousel__arrow--right {
            right: calc(4% + 1px);
          }

          .react-multiple-carousel__arrow--right::before {
            content: '\\e825';
          }

          .react-multi-carousel-dot-list {
            position: absolute;
            bottom: 14px;
            display: flex;
            left: 0;
            right: 0;
            justify-content: center;
            padding: 0;
            margin: 0;
            list-style: none;
            text-align: center;
          }

          .react-multi-carousel-dot button {
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            opacity: 1;
            padding: 5px 5px 5px 5px;
            box-shadow: none;
            transition: background 0.5s;
            border-width: 2px;
            border-style: solid;
            border-color: #5b6871;
            margin: 0 6px 0 0;
            outline: 0;
            cursor: pointer;
          }

          .react-multi-carousel-dot button:hover:active {
            background: #5b6871;
          }

          .react-multi-carousel-dot--active button {
            background: #5b6871;
          }

          .react-multi-carousel-item {
            transform-style: preserve-3d;
            backface-visibility: hidden;
          }

          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            .react-multi-carousel-item {
              flex-shrink: 0 !important;
            }

            .react-multi-carousel-track {
              overflow: visible !important;
            }
          }

          [dir='rtl'].react-multi-carousel-list {
            direction: rtl;
          }

          .rtl.react-multiple-carousel__arrow--right {
            right: auto;
            left: calc(4% + 1px);
          }

          .rtl.react-multiple-carousel__arrow--right::before {
            content: '\\e824';
          }

          .rtl.react-multiple-carousel__arrow--left {
            left: auto;
            right: calc(4% + 1px);
          }

          .rtl.react-multiple-carousel__arrow--left::before {
            content: '\\e825';
          }

          .rc-slider {
            position: relative;
            width: 100%;
            height: 14px;
            padding: 5px 0;
            border-radius: 6px;
            touch-action: none;
            box-sizing: border-box;
            -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
          }

          .rc-slider * {
            box-sizing: border-box;
            -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
          }

          .rc-slider-rail {
            position: absolute;
            width: 100%;
            height: 4px;
            background-color: rgb(217 217 217 / 40%);
            border-radius: 6px;
          }

          .rc-slider-track {
            position: absolute;
            height: 4px;
            background-color: ${theme.colors.accent};
            border-radius: 6px;
          }

          .rc-slider-handle {
            position: absolute;
            width: 20px;
            height: 20px;
            margin-top: -7px;
            background-color: #fff;
            border: solid 6px ${theme.colors.accent};
            border-radius: 50%;
            cursor: grab;
            touch-action: pan-x;
          }

          .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
            border-color: ${theme.colors.accent};
          }

          .rc-slider-handle:focus {
            outline: none;
            box-shadow: none;
          }

          .rc-slider-handle:focus-visible {
            border-color: ${theme.colors.accent};
            box-shadow: 0 0 0 3px ${theme.colors.accent};
          }

          .rc-slider-handle-click-focused:focus {
            border-color: ${theme.colors.accent};
            box-shadow: unset;
          }

          .rc-slider-handle:hover {
            border-color: ${theme.colors.accent};
          }

          .rc-slider-handle:active {
            border-color: ${theme.colors.accent};
            box-shadow: 0 0 5px ${theme.colors.accent};
            cursor: grabbing;
          }

          .rc-slider-mark {
            position: absolute;
            top: 18px;
            left: 0;
            width: 100%;
            font-size: 12px;
          }

          .rc-slider-mark-text {
            position: absolute;
            display: inline-block;
            color: #999;
            text-align: center;
            vertical-align: middle;
            cursor: pointer;
          }

          .rc-slider-mark-text-active {
            color: #666;
          }

          .rc-slider-step {
            position: absolute;
            width: 100%;
            height: 4px;
            background: transparent;
            pointer-events: none;
          }

          .rc-slider-dot {
            position: absolute;
            bottom: -2px;
            width: 8px;
            height: 8px;
            vertical-align: middle;
            background-color: #fff;
            border: 2px solid #e9e9e9;
            border-radius: 50%;
            cursor: pointer;
          }

          .rc-slider-dot-active {
            border-color: #96dbfa;
          }

          .rc-slider-dot-reverse {
            margin-right: -4px;
          }

          .rc-slider-disabled {
            background-color: #e9e9e9;
          }

          .rc-slider-disabled .rc-slider-track {
            background-color: #ccc;
          }

          .rc-slider-disabled .rc-slider-handle,
          .rc-slider-disabled .rc-slider-dot {
            background-color: #fff;
            border-color: #ccc;
            box-shadow: none;
            cursor: not-allowed;
          }

          .rc-slider-disabled .rc-slider-mark-text,
          .rc-slider-disabled .rc-slider-dot {
            cursor: not-allowed !important;
          }

          .rc-slider-vertical {
            width: 14px;
            height: 100%;
            padding: 0 5px;
          }

          .rc-slider-vertical .rc-slider-rail {
            width: 4px;
            height: 100%;
          }

          .rc-slider-vertical .rc-slider-track {
            bottom: 0;
            left: 5px;
            width: 4px;
          }

          .rc-slider-vertical .rc-slider-handle {
            margin-top: 0;
            margin-left: -5px;
            touch-action: pan-y;
          }

          .rc-slider-vertical .rc-slider-mark {
            top: 0;
            left: 18px;
            height: 100%;
          }

          .rc-slider-vertical .rc-slider-step {
            width: 4px;
            height: 100%;
          }

          .rc-slider-vertical .rc-slider-dot {
            margin-left: -2px;
          }

          .rc-slider-tooltip-zoom-down-enter,
          .rc-slider-tooltip-zoom-down-appear {
            display: block !important;
            animation-duration: 0.3s;
            animation-fill-mode: both;
            animation-play-state: paused;
          }

          .rc-slider-tooltip-zoom-down-leave {
            display: block !important;
            animation-duration: 0.3s;
            animation-fill-mode: both;
            animation-play-state: paused;
          }

          .rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
          .rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
            animation-name: rcSliderTooltipZoomDownIn;
            animation-play-state: running;
          }

          .rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
            animation-name: rcSliderTooltipZoomDownOut;
            animation-play-state: running;
          }

          .rc-slider-tooltip-zoom-down-enter,
          .rc-slider-tooltip-zoom-down-appear {
            transform: scale(0, 0);
            animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          }

          .rc-slider-tooltip-zoom-down-leave {
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          }

          @keyframes rcSliderTooltipZoomDownIn {
            0% {
              transform: scale(0, 0);
              transform-origin: 50% 100%;
              opacity: 0;
            }

            100% {
              transform: scale(1, 1);
              transform-origin: 50% 100%;
            }
          }

          @keyframes rcSliderTooltipZoomDownOut {
            0% {
              transform: scale(1, 1);
              transform-origin: 50% 100%;
            }

            100% {
              transform: scale(0, 0);
              transform-origin: 50% 100%;
              opacity: 0;
            }
          }

          .rc-slider-tooltip {
            position: absolute;
            top: -9999px;
            left: -9999px;
            visibility: visible;
            box-sizing: border-box;
            -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
          }

          .rc-slider-tooltip * {
            box-sizing: border-box;
            -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
          }

          .rc-slider-tooltip-hidden {
            display: none;
          }

          .rc-slider-tooltip-placement-top {
            padding: 4px 0 8px;
          }

          .rc-slider-tooltip-inner {
            min-width: 24px;
            height: 24px;
            padding: 6px 2px;
            color: #fff;
            font-size: 12px;
            line-height: 1;
            text-align: center;
            text-decoration: none;
            background-color: #6c6c6c;
            border-radius: 6px;
            box-shadow: 0 0 4px #d9d9d9;
          }

          .rc-slider-tooltip-arrow {
            position: absolute;
            width: 0;
            height: 0;
            border-color: transparent;
            border-style: solid;
          }

          .rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
            bottom: 4px;
            left: 50%;
            margin-left: -4px;
            border-width: 4px 4px 0;
            border-top-color: #6c6c6c;
          }
        `}
      />
    </div>
  );
};

export default BaselineCss;
